import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Context from "../../context/Context";
import { notification, Modal } from "antd";
import { accessType, auth_token, BASE_URL_V1 } from "../../config";
import axios from "axios";
//api called
import {
  profileAction,
  profileEditService,
} from "../../redux/action/profileAction";
import { gstAction } from "../../redux/action/gstAction";
import { FormOutlined } from "@ant-design/icons";
import Cookies from "universal-cookie";
import { pincodeAutoFill as pincodeAutoFillApi } from "../../redux/action/pincodeAutoFill";
import styles from "./profileViewUpdate.module.css";
import StateSelectSearch from "../selectSearch/stateSelectSearch";
import { formatState } from "../../helpers/globalFunction";

const ProfileView = () => {
  const context = useContext(Context);
  const { profileViewUpdateOpen, setProfileViewUpdateOpenOpen } = context;
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { pincodeAutoFill, editProfile } = state;
  const cookies = new Cookies();
  const [profileDetails, setProfileDetails] = useState("");
  const [detailsFromGst, setDetailsFromGst] = useState("");

  const [imgs, setImg] = useState([]);
  const [iconImageId, setIconImageId] = useState(null);
  const [isLoadingFile, setIsLoadingFile] = useState("");
  const [uploadImageError, setUploadImageError] = useState(false);
  const [someError, setSomeError] = useState("");

  const [formInput, setFormInput] = useState(initialValue);

  const handleChange = (event) => {
    setFormInput((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };

  const onClose = () => {
    setProfileViewUpdateOpenOpen(false);
    setFormInput(initialValue);
  };

  // useEffect(() => {
  //   dispatch(profileAction());
  // }, [profileViewUpdateOpen]);

  useEffect(() => {
    if ((formInput.primary_gstin?.length === 15) & profileViewUpdateOpen) {
      dispatch(gstAction(formInput.primary_gstin));
    }
  }, [formInput.primary_gstin]);

  useEffect(() => {
    if (state.profile.data !== "") {
      if (state.profile.data.data.error === false)
        setProfileDetails(state.profile.data.data.data);
    }
    if (state.gst.data !== "") {
      if (state.gst.data.data.error === false)
        setDetailsFromGst(state.gst.data.data.data);
    }
    if (pincodeAutoFill.data && !pincodeAutoFill.data.data.error) {
      let tempData = pincodeAutoFill.data.data[0].PostOffice[0];
      setFormInput((prevState) => ({
        ...prevState,
        state: formatState(tempData.State),
        city: tempData.District,
      }));
    }
    if (editProfile.data && !editProfile.data.data.error) {
      dispatch(profileAction());
      setProfileViewUpdateOpenOpen(false);
    }
  }, [state]);

  useEffect(() => {
    setFormInput((prevState) => ({
      ...prevState,
      legal_name: profileDetails.legal_name,
      email: profileDetails.email,
      mobile: profileDetails.mobile,
      address_line_1: profileDetails.address_line_1,
      primary_gstin: profileDetails.primary_gstin,
      city: profileDetails.city,
      state: profileDetails.state,
      pincode: profileDetails.pincode,
      first_name: profileDetails.first_name,
      last_name: profileDetails.last_name,
      logo_image: profileDetails.logo_image,
    }));
  }, [profileDetails]);

  const onFinish = () => {
    cookies.set("rupyzUserName", formInput.legal_name, { path: "/" });
    dispatch(profileEditService(formInput));
  };

  const uploadedImage = React.useRef(null);
  const imageUploader = React.useRef(null);

  const handleImageUpload = (e) => {
    const [file] = e.target.files;
    setImg(e.target.files[0]);
    if (file) {
      const reader = new FileReader();
      const { current } = uploadedImage;
      current.file = file;
      reader.onload = (e) => {
        current.src = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  };

  async function handleUpload(e, type) {
    if (imgs) {
      let type = imgs.type?.split("/")[0];
      let name = imgs.name;
      let content_type = imgs.type.split("/")[1];
      setIsLoadingFile([...isLoadingFile, type]);
      e.preventDefault();
      var formdata_s3_1 = new FormData();
      formdata_s3_1.append("type", type);
      formdata_s3_1.append("file_name", name);
      formdata_s3_1.append("content_type", content_type);
      formdata_s3_1.append(
        "prev_s3_id",
        profileDetails.profile_pic ? profileDetails.profile_pic : 0
      );

      //image s3 uplaod api

      try {
        const response_s3_1 = await axios({
          method: "post",
          headers: {
            Authorization: auth_token,
          },
          url: `${BASE_URL_V1}/s3/upload/`,
          data: formdata_s3_1,
        });
        let arrId = Number(response_s3_1.data.data[0].id);

        let formdata_s3_2 = new FormData();
        formdata_s3_2.append("key", response_s3_1.headers.key);
        formdata_s3_2.append(
          "x-amz-algorithm",
          response_s3_1.headers.x_amz_algorithm
        );
        formdata_s3_2.append(
          "x-amz-signature",
          response_s3_1.headers.x_amz_signature
        );
        formdata_s3_2.append("x-amz-date", response_s3_1.headers.x_amz_date);
        formdata_s3_2.append("Policy", response_s3_1.headers.policy);
        formdata_s3_2.append(
          "success_action_status",
          response_s3_1.data.data[0].success_action_status
        );
        formdata_s3_2.append(
          "x-amz-credential",
          response_s3_1.headers.x_amz_credential
        );
        formdata_s3_2.append(
          "Content-Type",
          response_s3_1.data.data[0].content_type
        );
        formdata_s3_2.append(
          "Content-Disposition",
          response_s3_1.data.data[0].content_disposition
        );
        formdata_s3_2.append("acl", response_s3_1.data.data[0].acl);
        formdata_s3_2.append("file", imgs, imgs.name);

        try {
          //image upload api
          const response_s3_2 = await axios({
            method: "post",
            data: formdata_s3_2,
            url: response_s3_1.headers.upload_url,
          });

          try {
            // image uplaod comfirm api

            const confirm = await axios({
              method: "post",
              headers: {
                Authorization: auth_token,
              },
              url: `${BASE_URL_V1}/s3/confirm/`,
              data: {
                file_id: response_s3_1.data.data[0].id,
              },
            });
            let id = confirm.data.data.id;
            setFormInput((prevState) => ({
              ...prevState,
              logo_image: id,
            }));
            if (response_s3_1.data.data) {
              notification.success({
                message: "Logo Upload Successfully",
              });
            }
            setIconImageId(response_s3_1.data.data[0].id);
          } catch (error) {
            setIsLoadingFile([]);
            setSomeError("Something went wrong.");
          }
        } catch (error) {
          setIsLoadingFile([]);
          setSomeError("Something went wrong.");
        }
      } catch (error) {
        setIsLoadingFile([]);
        setSomeError("Something went wrong.");
      }
    } else {
      setUploadImageError(true);
    }
  }

  return (
    <>
      {accessType === "WEB_SARE360" && profileDetails ? (
        <Modal
          title={
            <div
              style={{
                padding: 15,
                textAlign: "center",
                fontSize: 18,
                fontWeight: 600,
              }}
            >
              Edit Organization Profile
            </div>
          }
          width={700}
          onCancel={onClose}
          open={profileViewUpdateOpen}
          centered
          footer={[
            <div
              style={{
                marginTop: 20,
                display: "flex",
                background: "#fff",
                padding: 15,
                flexDirection: "row-reverse",
                borderRadius: "0 0 10px 10px",
              }}
            >
              <button
                className="button_primary"
                style={{ marginRight: 20 }}
                onClick={onFinish}
              >
                Update
              </button>
              <button
                className="button_secondary"
                style={{ marginRight: 20 }}
                onClick={onClose}
              >
                Cancel
              </button>
            </div>,
          ]}
        >
          <div className={styles.profile_container}>
            <div>
              <label style={{ fontWeight: "600" }}>
                Logo
                <span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="file"
                accept="image/*"
                onChange={handleImageUpload}
                ref={imageUploader}
                style={{
                  display: "none",
                  border: "none",
                  borderRadius: "50%",
                }}
                required
              />
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "20px",
                  marginBottom: "20px",
                  marginTop: "5px",
                }}
              >
                <div
                  style={{
                    height: "150px",
                    width: "270px",
                    border: "1px solid #EEEEEE ",
                    borderRadius: "16px",
                  }}
                >
                  <img
                    ref={uploadedImage}
                    src={
                      profileDetails.logo_image_url
                        ? profileDetails.logo_image_url
                        : ""
                    }
                    style={{
                      width: "100%",
                      height: "100%",
                      borderRadius: "16px",
                    }}
                  />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  gap: "20px",
                }}
              >
                <button
                  className="button_secondary"
                  onClick={() => imageUploader.current.click()}
                >
                  <FormOutlined />
                  &nbsp;&nbsp; Change
                </button>
                <button
                  className="button_primary"
                  type="primary"
                  onClick={(e) => {
                    handleUpload(e, "feature");
                  }}
                >
                  {" "}
                  <i
                    className={`bx ${
                      isLoadingFile === "feature" ? "bx-loader bx-spin" : null
                    } font-size-16 align-middle me-2`}
                  ></i>
                  {iconImageId ? (
                    <i className="bx bx-check-double font-size-16 align-middle me-2"></i>
                  ) : null}
                  {iconImageId ? "Uploaded" : "Upload"}
                </button>
              </div>
            </div>
            <br />
            <form>
              <label style={{ fontWeight: "600" }}>GST</label>
              <input
                name="primary_gstin"
                value={formInput.primary_gstin}
                onChange={handleChange}
                minLength="15"
                maxLength="15"
              />
              <label style={{ fontWeight: "600" }}>Company Name</label>
              <input
                value={formInput.legal_name}
                name="legal_name"
                onChange={handleChange}
                onKeyPress={(e) => {
                  if (!/[A-Z0-9a-z ]/.test(e.key)) {
                    e.preventDefault();
                  }
                }}
              />
              <label style={{ fontWeight: "600" }}>Mobile</label>
              <input
                type="mobile"
                name="mobile"
                minLength="10"
                maxLength="10"
                value={formInput.mobile}
                onChange={handleChange}
              />
              <label style={{ fontWeight: "600" }}>Mail</label>
              <input
                type="email"
                name="email"
                value={formInput.email}
                onChange={handleChange}
              />
              <label style={{ fontWeight: "600" }}>Address</label>
              <input
                name="address_line_1"
                onChange={handleChange}
                value={formInput.address_line_1}
              />{" "}
              <label style={{ fontWeight: "600" }}>Pincode</label>
              <input
                value={formInput.pincode}
                name="pincode"
                onChange={(event) => {
                  handleChange(event);
                  if (event.target.value.length === 6) {
                    dispatch(pincodeAutoFillApi(event.target.value));
                  }
                }}
              />
              <label style={{ fontWeight: "600" }}>City</label>
              <input
                name="city"
                value={formInput.city}
                onChange={handleChange}
              />
              <label style={{ fontWeight: "600" }}>State</label>
              <StateSelectSearch
                value={formInput.state}
                state={(data) =>
                  setFormInput((prev) => ({ ...prev, state: data }))
                }
              />
              <label style={{ fontWeight: "600" }}>First Name</label>
              <input
                value={formInput.first_name}
                name="first_name"
                onChange={handleChange}
              />
              <label style={{ fontWeight: "600" }}>Last Name</label>
              <input
                name="last_name"
                value={formInput.last_name}
                onChange={handleChange}
              />
            </form>
          </div>
        </Modal>
      ) : (
        <></>
      )}
    </>
  );
};

export default ProfileView;

const initialValue = {
  legal_name: "",
  email: "",
  mobile: "",
  address_line_1: "",
  primary_gstin: "",
  city: "",
  state: "",
  pincode: "",
  first_name: "",
  last_name: "",
  logo_image: "",
};
