import { useContext } from "react";
import styles from "./styles.module.css";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Context from "../../../context/Context";
import CustomerFilters from "./customerFilters";
import CustomerDetailCard from "./customerDetailCard";
import { useDispatch, useSelector } from "react-redux";
import SearchInput from "../../search-bar/searchInput";
import noDataIcon from "../../../assets/globle/no-results.svg";
import { customerDistributor as customerDistributorAPI } from "../../../redux/action/customerAction";

export default function CustomerDownLevelList({ data }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const context = useContext(Context);
  const state = useSelector((state) => state);

  const customerLevel = data?.customer_level
    ? "LEVEL-" +
      (Number(data?.customer_level[data?.customer_level?.length - 1]) + 1)
    : "";

  const { setOpenDistributorDrawer } = context;

  const initialFilters = {
    sort_by: "name",
    sort_order: "ASC",
    staff_id: "",
    customer_type: "",
  };

  const [customerList, setCustomerList] = useState([]);
  const [search, setSearch] = useState();
  const [filters, setFilters] = useState(initialFilters);

  useEffect(() => {
    data.id &&
      dispatch(
        customerDistributorAPI(
          customerLevel,
          1,
          filters.staff_id || 0,
          data?.id,
          search,
          filters.sort_by || "name",
          filters.sort_order || "ASC",
          filters.customer_type,
          true
        )
      );
  }, [data?.id, filters, search]);

  useEffect(() => {
    if (
      state.getCustomerClients.data &&
      !state.getCustomerClients.data.data.error
    )
      setCustomerList(state.getCustomerClients.data.data.data);
    if (
      state.deleteCustomer.data &&
      !state.deleteCustomer.data.data.error &&
      !state?.deleteCustomer?.data?.params?.check_children
    ) {
      dispatch(customerDistributorAPI(customerLevel, "", 0, data?.id, true));
    }
  }, [state]);

  useEffect(() => {
    if (data?.id) {
      setSearch();
    }
  }, [data?.id]);

  return (
    <div className={styles.flex_col}>
      <SearchInput
        defaultQuery={false}
        searchValue={(data) =>
          setTimeout(() => {
            setSearch(data);
          }, 500)
        }
        value={search}
      />
      <CustomerFilters
        selectedValue={(data) => {
          setFilters({
            sort_by: data.sortBy,
            sort_order: data.sortOrder,
            staff_id: data.staff_id,
            customer_type: data.customerTypeList.join(","),
          });
        }}
      />
      {customerList?.length > 0 ? (
        customerList?.map((ele, index) => (
          <>
            {index < 10 && (
              <CustomerDetailCard
                key={ele?.id}
                data={ele}
                isCustomerClient={true}
                hideActionButton={true}
              />
            )}
            {index === 10 && (
              <p
                className={styles.view_all}
                onClick={() => {
                  setOpenDistributorDrawer(false);
                  navigate(
                    `/web/customer?customer_parent_id=${ele?.customer_parent}&name=${ele?.customer_parent_name}&customer_level=${customerLevel}&parent_customer_level=${data?.customer_level}`
                  );
                }}
              >
                View All
              </p>
            )}
          </>
        ))
      ) : (
        <div style={{ textAlign: "center" }}>
          <img src={noDataIcon} alt="no results" width={300} />
          <p style={{ color: "#727176" }}>No Matching Results</p>
        </div>
      )}
    </div>
  );
}
